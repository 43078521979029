<template>
  <div>
    <h1><span class="title-point">·</span>&nbsp;{{title}}&nbsp;<span class="title-point">·</span></h1>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
h1 {
        margin-bottom: 40px;
        .title-point{
          color: $primaryColor;
        }
      }
</style>